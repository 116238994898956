@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Roboto+Condensed:wght@300;400;700&display=swap');

#partner-section-2 h4 {
    width: 100%;
    text-align: center;
}

#partner-section-2 h2 {
    font-size: 2.375rem;
    font-weight: 900!important;
    font-style: normal;
    line-height: 2.625rem;
    letter-spacing: 0;
    width: 100%;
    text-align: center;
    font-family: 'Lato', sans-serif;
}

#partner-section-2 {
    padding: 50px 0px;
}


.btn{
    background: #fba919;
}