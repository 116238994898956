#partner-section-4 {
  padding: 50px 0px;
}

.p-d-f-1-b{
  height: 7.8rem;
  max-height: 100%;
}

.p-d-f-1-i{
height: 7.8rem;
max-height: 100%;
}

.s-3-f-sec >p {
  text-indent: -26px;
  padding-left:24px;
}


.mx-h-full {
  min-height: 100%;
  height: 13.5em;
  max-height: 100%;
}

#partner-section-4 h2 {
  font-size: 2.375rem;
  font-weight: 900 !important;
  font-style: normal;
  line-height: 2.625rem;
  letter-spacing: 0;
  width: 100%;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.m-p-bg-color {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  border-radius: 0.25rem;
}

.payment-t-s {
  font-size: 0.888rem;
}

.features-t-s {
  font-size: 0.888rem;
}

.payment-t-s-extra {
  font-size: 0.7rem;
}

.i-f-s {
  font-size: 1.255rem;
  margin-top: -2px;
}

.btn {
  background: #fba919;
}

.enter-m-p {
  margin-bottom: 1rem !important;
}

.text-gray-300 {
  color: rgb(156 163 175);
}

.p-m-sp {
  margin-top: 0;
  margin-bottom: 0;
}

.p-f-p-c {
  font-size: 0.8125rem;
}

.c-p-color {
  border: 1px solid rgb(217 119 6);
}

.btn-c-g-s {
  background-color: rgb(217 119 6);
  color: #fff;
  font-weight: 600 !important;
  font-size: 0.975rem !important;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.btn-c-g-s:hover {
  background-color: rgb(148, 83, 8);
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
}

.btn-c-g-s-g {
  background-color: rgb(107 114 128);
  color: #fff;
  font-weight: 600 !important;
  font-size: 0.975rem !important;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.btn-c-g-s-g:hover {
  background-color: rgb(217 119 6);
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
}

.price-card h4 {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0;
  color: #252525;
  margin-top: 25px;
}

.price-card {
  text-align: left;
  display: inline-block;
  padding: 35px;
  border-radius: 5px;
  background-color: rgb(31 41 55);
  box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
  /* transition: transform 300ms ease-out;
      will-change: transform; */
  width: auto;
  height: auto;
  transition: all 0.2s ease-in;
}

.price-card:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in;
  border: 1px solid rgb(217 119 6);
}

.price-card:hover .btn-c-g-s-g {
  background-color: rgb(217 119 6);
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
}

.s-3-p-sec {
  min-height: 100%;
  height: 9em;
  max-height: 100%;
}

.s-3-f-sec {
  min-height: 100%;
  height: 14em;
  max-height: 100%;
}

@media (min-width: 320px) and (max-width: 479px) {
  .tall-card {
    margin-bottom: 20px;
  }

  .p-d-f-1-b{
    height: 8.2rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 8.2rem;
  max-height: 100%;
}

  .mx-h-full {
    min-height: 100%;
    height: 13.5em;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 25px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
    margin-bottom: 1.5rem;
  }

  .container-sm {
    max-width: 369px;
  }

  #partner-section-4 h2 {
    font-size: 1.8rem;
    max-width: 369px;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .tall-card {
    margin-bottom: 20px;
  }

  #partner-section-4 h2 {
    font-size: 1.8rem;
  }

  .p-d-f-1-b{
    height: 8.8rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 8.8rem;
  max-height: 100%;
}

  .mx-h-full {
    min-height: 100%;
    height: 13.5em;
    max-height: 100%;
  }
  .price-card {
    text-align: left;
    display: inline-block;
    padding: 25px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
    margin-bottom: 1.5rem;
  }

  .container-sm {
    max-width: 369px;
  }

  #partner-section-4 h2 {
    font-size: 1.8rem;
    max-width: 369px;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .tall-card {
    margin-bottom: 20px;
  }

  .p-d-f-1-b{
    height: 7.8rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 7.8rem;
  max-height: 100%;
}

  .mx-h-full {
    min-height: 100%;
    height: 13em;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 25px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
    margin-bottom: 1.5rem;
  }

  .container-sm {
    max-width: 350px;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }

  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 899px) {
  .tall-card {
    margin-bottom: 20px;
  }

  .mx-h-full {
    min-height: 100%;
    height: 16em;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }
  .s-3-p-sec {
    min-height: 100%;
    height: 12.5rem;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .p-d-f-1-b{
    height: 10.3rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 10.3rem;
  max-height: 100%;
}


  .features-t-s {
    font-size: 0.788rem;
  }

  .payment-t-s {
    font-size: 0.788rem;
  }

  .container, .container-md, .container-sm {
    max-width: 820px;
  }

  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {

  .p-d-f-1-b{
    height: 9.3rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 9.3rem;
  max-height: 100%;
}

  .tall-card {
    margin-bottom: 20px;
  }

  .mx-h-full {
    min-height: 100%;
    height: 15em;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 12.5rem;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .features-t-s {
    font-size: 0.788rem;
  }

  .payment-t-s {
    font-size: 0.788rem;
  }

  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }

  .container, .container-md, .container-sm {
    max-width: 920px;
  }
}

@media (min-width: 1001px) and (max-width: 1059px) { 
  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }

  .p-d-f-1-b{
    height: 7.9rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 7.9rem;
  max-height: 100%;
}

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .mx-h-full {
    min-height: 100%;
    height: 14rem;
    max-height: 100%;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 11rem;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .container-md {
    max-width: 1060px;
  }
}

@media (min-width: 1060px) and (max-width: 1150px) { 
  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }

  .p-d-f-1-b{
    height: 7.9rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 7.9rem;
  max-height: 100%;
}

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 25px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .mx-h-full {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 11rem;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .container-md {
    max-width: 1060px;
  }
}

@media (min-width: 1151px) and (max-width: 1280px) {

  .mx-h-full > p{
    font-size: 0.92rem;
  }

  .p-m-sp > span{
    font-size: 0.8rem;
  }

  .p-d-f-1-b{
    height: 8rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 8rem;
  max-height: 100%;
}

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 25px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .mx-h-full {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .s-3-p-sec {
    min-height: 100%;
    height: 11rem;
    max-height: 100%;
  }

  .s-3-f-sec {
    min-height: 100%;
    height: 13.5rem;
    max-height: 100%;
  }

  .container-md {
    max-width: 1160px;
  }
}

@media (min-width: 1281px) and (max-width: 1400px) {

  .p-d-f-1-b{
    height: 8.5rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 8.5rem;
  max-height: 100%;
}

  .mx-h-full {
    min-height: 100%;
    height: 14rem;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 14px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .container-md {
    max-width: 1150px;
  }
}

@media (min-width: 1401px) {

  .p-d-f-1-b{
    height: 8.3rem;
    max-height: 100%;
  }

.p-d-f-1-i{
  height: 8.3rem;
  max-height: 100%;
}

  .mx-h-full {
    min-height: 100%;
    height: 14rem;
    max-height: 100%;
  }

  .price-card {
    text-align: left;
    display: inline-block;
    padding: 14px;
    border-radius: 5px;
    background-color: rgb(31 41 55);
    box-shadow: 0px 4px 75px rgb(0 0 0 / 10%);
    /* transition: transform 300ms ease-out;
        will-change: transform; */
    width: auto;
    height: auto;
    transition: all 0.2s ease-in;
  }

  .container-md {
    max-width: 1160px;
  }
}
