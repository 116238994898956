@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');

.brand img {
  font-size: 75px;
  font-family: 'Yellowtail', cursive;
  color: #F2A03F;
  height: 60px;
}

.download-tag {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 35px;
}

#footer {
  height: 500px;
  background: black;
  z-index: 100000;
  position: relative;
  text-align: center;
  padding: 75px 0px;
  height: auto;
}

#footer ul {
  list-style: none;
}

#footer li {
  color: white;
  text-decoration: none;
  text-align: left;
  padding: 0px 10px;
  margin-right: 20px;
}

#footer .divider {
  width: 0px !important;
  text-align: center;
}

#footer ul a {
  text-decoration: none;
  color: white;
}

.store-button-footer {
  border: 1px solid white;
  height: 50px;
  border-radius: 5px;
  position: relative;
  margin: 0px 20px;
}

.store-wrapper-footer {
  margin: auto;
  width: 100%;
  text-align: center;
  display: inline;
  justify-content: center
}

@media screen and (max-width: 980px) {
  .store-button-footer {
    margin: 20px;
    width: 150px;
  }

  #footer li {
    text-align: center;
  }
}

.ease-all {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/* Social Icons */

.social {
  height: 1rem;
  list-style-type: none;
  margin-top: 1rem !important;
  padding: 0;
  text-align: center;
}

.social li a {
  display: inline-block;
  float: left;
  width: 44px;
  height: 44px;
  text-align: center;
  padding-top: 10px;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}

.fa {
  font-size: 28px;
}

.social li .face-book {
  background-color: #4267B2 !important;
  color: #fff !important;
}

.social li .instagram {
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf) !important;
  color: #fff !important;
}

.social li .face-book:hover {
  -webkit-box-shadow: inset 0 0 0 3px #4267B2;
  -moz-box-shadow: inset 0 0 0 3px #4267B2;
  -o-box-shadow: inset 0 0 0 3px #4267B2;
  -ms-box-shadow: inset 0 0 0 3px #4267B2;
  box-shadow: inset 0 0 0 3px #4267B2;
  background-color: #4267B2;
  color: #fff !important;
}

.social li .instagram:hover {
  -webkit-box-shadow: inset 0 0 0 0px #d92e7f;
  -moz-box-shadow: inset 0 0 0 0px #d92e7f;
  -o-box-shadow: inset 0 0 0 0px #d92e7f;
  -ms-box-shadow: inset 0 0 0 0px #d92e7f;
  box-shadow: inset 0 0 0 0px #d92e7f;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  color: #fff !important;
}