@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}

.playpause {
    cursor: pointer;
}

.mob-img {
    display: none;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .logo {
    max-width: 150px;
}

.header-right {
    margin-right: 50px;
}

.partners {
    background-color: #f89f3f;
    color: #fff;
    border-radius: 20px;
    padding: 6px 20px;
    font-size: 17px;
    font-weight: 500;
}

.partners {
    background-color: #f89f3f;
    color: #fff;
    border-radius: 20px;
    padding: 6px 20px;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
}


.top-sec-details {
    color: #fff;
    max-width: 781px;
    margin: auto;
    padding: 80px 0;
    text-align: center;
}

.top-sec-details h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 66px;
}

.top-sec-details h4 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 41px;
}

.left-side-img {
    max-width: 420px;
    margin-left: auto;
    /*margin-right: 60px;*/
}

.right-side-content {
    max-width: 420px;
}

.header-title {
    color: #f79f3f;
    font-size: 38px;
    position: relative;
    font-weight: 600;
}

.header-title::before {
    content: "";
    position: absolute;
    top: -10px;
    border-top: 5px solid #f79f3f;
    width: 41px;
}

.detail-text {
    color: #8e8e8e;
    font-size: 19px;
    font-weight: 400;
    margin-top: 35px;
    line-height: 38px;

}

.sec {
    padding-top: 60px;
    min-height: 90vh;
}

.content-ml-auto {
    margin-left: auto;
}

.right-side-img {
    max-width: 420px;
}


.left-side-img-1 {
    max-width: 370px;
    margin-left: auto;
    margin-right: 60px;
}

.footer-side-content {
    max-width: 340px;
}

.content-header-white {
    font-weight: 600;
    font-size: 40px;
    color: #ffffff;
    line-height: 44px;

}

.detail-text {
    color: #8e8e8e;
    font-size: 19px;
    font-weight: 400;
    margin-top: 35px;
    line-height: 38px;

}

.btn-get-started {
    background-color: #f89f3f;
    color: #fff;
    border-radius: 5px;
    padding: 6px 30px;
    font-size: 29px;
    font-weight: 600;

}

.partner_slider-content {
    color: #fff;
    max-width: 600px;
    padding: 80px 0;
}

.mob-video {
    display: none;
    margin-bottom: 35px;
    position: relative;
}

.mob-video video {
    width: 100%;
}

.playpause {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000bd;
}

.partner_slider-content h1 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 50px;
    line-height: 56px;

}

.partner_slider-content p {
    font-size: 19px;
    font-weight: 400;
}

.partner-btn-groups {
    display: flex;
    margin-top: 35px;
}

.btn-partners {
    background-color: #f89f3f;
    color: #fff;
    border-radius: 20px;
    padding: 6px 20px;

}

.play-video-btn {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 17px;
    padding: 5px 25px;
    margin-left: 30px;
    max-width: 235px;
    width: 100%;

}

.play-video-btn:hover {
    color: #fff;
}

.play-video-btn span img {
    max-width: 18px;
    margin-right: 12px;
    position: relative;
    top: -1px;
}

.section-service {
    position: relative;
    min-height: 50vh;
    padding-top: 60px;
}

.service-head {
    margin-bottom: 40px;
}

.service-head h1 {
    color: #fff;
    font-weight: 600;
    font-size: 46px;
    text-align: center;
}

.service-container {
    text-align: center;
    padding: 25px 20px;
    background: #272727;
    background: -webkit-linear-gradient(top, #272727, #f9f9f900);
    background: -moz-linear-gradient(top, #272727, #f9f9f900);
    background: linear-gradient(to bottom, #272727, #f9f9f900);
}

.service-container .service-logo {
    background: #2d2d2d;
    border-radius: 108px;
    height: 170px;
    width: 170px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-list h4 {
    color: #f89f3f;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
}

.service-list .service-content p {
    font-size: 16px;
    color: #d3d3d3;
    text-align: center;
}

.section-image-content {
    max-width: 310px;
}

.section-image-content h2 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}

.section-steps .steps {
    display: flex;
}

.step-img {
    background: #333333;
    border-radius: 100px;
    height: 100px;
    max-width: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.step-content {
    margin-left: 25px;
}

.title h4 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 60px;
}

.step-content h4 {
    color: #f89f3f;
    font-size: 20px;
    font-weight: 600;
}

.step-content p {
    color: #e5e5e5;
    font-size: 17px;
    font-weight: 400;
}

.left-side-img-5 {
    max-width: 475px;
    margin-left: auto;
    margin-right: 60px;
}

.signup-form {
    padding: 20px;
    max-width: 430px;
    border-radius: 15px;
    border: 1px solid #ffffff5e;
}

.signup-form h4 {
    color: #f89f3f;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    max-width: 240px;
    margin: auto;
    margin-bottom: 25px;
}

.signup-form .form-control {
    background: #1e1e1e;
    border-radius: 8px;
    height: 59px;
    color: #9f9f9f;
    border: 1px solid #ffffff29;
}

.btn-submit {
    width: 100%;
    color: #fff;
    background: #f89f3f;
    height: 66px;
    border-radius: 8px;
}

.login-outer {
    min-height: 100vh;
    padding: 0;
    background: #000;
    position: relative;
    background-image: url(https://barm8-space1.sgp1.cdn.digitaloceanspaces.com/default-img/login.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.login-outer::before {
    background-image: url(https://barm8-space1.sgp1.cdn.digitaloceanspaces.com/default-img/login.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    position: absolute;
}

.login-box {
    position: relative;
    z-index: 99;
    top: 30px;
}

.logo-section {
    max-width: 250px;
    margin: 0 auto;
}

.logo-section img {
    max-width: 100%;
}

.form-section {
    max-width: 625px;
    margin: 0 auto;
    padding: 30px 50px 0;
    border-radius: 3px;
    background: #151541;
    background: -webkit-linear-gradient(top, #151541, #24c62700);
    background: -moz-linear-gradient(top, #151541, #24c62700);
    background: linear-gradient(to bottom, #151541, #24c62700);
}

.form-section h2 {
    margin: 0;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding-bottom: 25px;
}

.form-section h2:after {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background: #FAA141;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    bottom: 12px;
}

.header-text .form-section p {
    font-size: 16px;
    color: #c5c5c5;
    max-width: 300px;
    margin: 0 auto;
}

form .cc-form {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 5px;
}

form .cc-form input[type=email],
form .cc-form input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 14px 0;
    display: inline-block;
    border: 0px solid #5b5b61;
    box-sizing: border-box;
    background: transparent;
    color: #fff;
    border-radius: 5px;
}

form .psw {
    display: block;
    text-align: left;
    color: #c5c5c5;
    padding-bottom: 20px;
}

form .psw a {
    color: #f69f41;

}

form button {
    background: #FBA919;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 18px 64px;
    border-radius: 5px;
    border: 0;
}

form .cc-form .form-control {
    background-color: transparent;
    box-shadow: 1px 5px 12px 0px #f69f41;
    border-top: 0;
    margin-bottom: 40px;
    border-radius: 9px;
    border: 0;
    color: #fff;
    height: 60px;
    padding: 30px;
}




.footer {
    background-color: #fba919;
    padding: 30px 0;
}

.footer-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-logo {
    max-width: 150px;
}

.icons-li ul {
    list-style-type: none;
    display: inline;
}

.icons-li ul li {
    display: inline;
    justify-content: flex-start;
    padding: 0 5px 0 5px;
}

.icons {
    max-width: 30px;
}

.footer-menu ul {
    padding-left: 0;
    display: inline-flex;
}

.footer-menu ul li {
    list-style-type: none;
    float: right;
    color: #fff;
    padding: 0px 10px 0px 10px;
}

.footer-menu ul li a {
    color: #fff !important;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.copyright-section {
    border-top: 1px solid #f7f7ff2b;
    background-color: #fba919;
    padding: 5px 0;
}

.copyright-text p {
    font-size: 17px;
    color: #fff;
    text-align: center;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.top-sec-contact {
    position: relative;
    top: 25px;
    /*margin-bottom: 200px;*/
}

.contact {
    margin-top: 83px;
    margin-bottom: 100px;
}

.contact .details {
    margin-bottom: 35px;
}

.contact .details h2 {
    color: #f79f3f;
    font-size: 38px;
    position: relative;
    font-weight: 600;
}

.contact .details p {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
    line-height: 38px;
}

.contact-details {
    color: #fff;
    padding: 120px 0px 0px 25px;
}

.contact-details h4 {
    color: #f79f3f;
    font-size: 38px;
    position: relative;
    font-weight: 600;
}

.contact-details p {
    font-size: 19px;
    font-weight: 400;
}

.contact-details a {
    color: #fff;
}

.contact-form-section {
    margin: 0 auto;
    padding: 40px 50px 0;
    border-radius: 3px;
    background: #151541;
    background: -webkit-linear-gradient(top, #151541, #24c62700);
    background: -moz-linear-gradient(top, #151541, #24c62700);
    background: linear-gradient(to bottom, #151541, #24c62700);
}

.contact-form-section h2 {
    margin: 0;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding-bottom: 25px;
}

.contact-form-section h2:after {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background: #FAA141;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    bottom: 12px;
}

.header-text .contact-form-section p {
    font-size: 16px;
    color: #c5c5c5;
    max-width: 300px;
    margin: 0 auto;
}

form .cc-form-1 {
    display: block;
    margin: 0 auto;
    padding-top: 15px;
}

form .cc-form-1 input[type=email],
form .cc-form-1 input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 14px 0;
    display: inline-block;
    border: 1px solid #5b5b61;
    box-sizing: border-box;
    background: transparent;
    color: #fff;
    border-radius: 5px;
}

form .cc-form-1 .form-control {
    background-color: transparent;
    box-shadow: 1px 5px 12px 0px #f69f41;
    border-top: 0;
    border-radius: 9px;
    border: 0;
    color: #fff;
    height: 60px;
    padding: 30px;
}

.terms-detail {
    margin-top: 50px;
    color: #212529;
    ;
}

.terms-detail p {
    margin-left: 15px;
}

.input-group-text {
    border-radius: 0rem;
    background-color: transparent;
    box-shadow: 1.2px 5.5px 18px 2px #f69f41;
}

input::-webkit-input-placeholder {
    text-align: center
}

.input-group-text {
    line-height: 2.8;
    background-color: #f89f3f;
    border: 1px solid #f89f3f;
    color: #fff;
    box-shadow: 1.2px 5.5px 18px 2px #f69f41;
    cursor: pointer;
}

.sub-text {
    background-color: transparent;
    height: calc(2.8em + .75rem + 2px);
    box-shadow: 1.2px 5.5px 18px 2px #f69f41;
    border: 0px solid #ced4da;
    color: #fff;
}

.sub-text:focus {
    background-color: transparent;
    height: calc(2.8em + .75rem + 2px);
    box-shadow: 1.2px 5.5px 18px 2px #f69f41;
    border: 0px solid #ced4da;
    color: #fff;
}

.sub-err-d {
    margin-top: 0.8rem;
    text-align: initial;
}

#sub-text-err {
    color: red;
    display: none;
}


@media only screen and (max-width: 600px) {
    .sub-text {
        font-size: 1.02rem;
    }

    .sub-email-submit {
        font-size: 0.88rem;
    }
}


.brand img {
    font-size: 75px;
    font-family: 'Yellowtail', cursive;
    color: #F2A03F;
    height: 60px;
}

.download-tag {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 35px;
}

#footer {
    height: 500px;
    background: black;
    z-index: 100000;
    position: relative;
    text-align: center;
    padding: 30px 0px;
    height: auto;
}



#footer .divider {
    width: 0px !important;
    text-align: center;
}

#footer ul a {
    text-decoration: none;
    color: white;
}

.store-button-footer {
    border: 1px solid white;
    height: 50px;
    border-radius: 5px;
    position: relative;
    margin: 0px 20px;
}

.store-wrapper-footer {
    margin: auto;
    width: 100%;
    text-align: center;
    display: inline;
    justify-content: center
}

@media screen and (max-width: 980px) {
    .store-button-footer {
        margin: 20px;
        width: 150px;
    }

    #footer li {
        text-align: center;
    }
}

.disc-link-wrapper {
    margin-top: 1.6rem;
}

.social {
    height: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.social li a {
    display: inline-block;
    float: left;
    width: 44px;
    height: 44px;
    text-align: center;
    padding-top: 10px;

    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
}

.fa {
    font-size: 28px;
}

.social li .face-book {
    background-color: #4267B2 !important;
    color: #fff !important;
}

.social li .instagram {
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf) !important;
    color: #fff !important;
}

.social li .face-book:hover {
    -webkit-box-shadow: inset 0 0 0 3px #4267B2;
    -moz-box-shadow: inset 0 0 0 3px #4267B2;
    -o-box-shadow: inset 0 0 0 3px #4267B2;
    -ms-box-shadow: inset 0 0 0 3px #4267B2;
    box-shadow: inset 0 0 0 3px #4267B2;
    background-color: #4267B2;
    color: #fff !important;
}

.social li .instagram:hover {
    -webkit-box-shadow: inset 0 0 0 0px #d92e7f;
    -moz-box-shadow: inset 0 0 0 0px #d92e7f;
    -o-box-shadow: inset 0 0 0 0px #d92e7f;
    -ms-box-shadow: inset 0 0 0 0px #d92e7f;
    box-shadow: inset 0 0 0 0px #d92e7f;
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
    color: #fff !important;
}

#social-footer {
    margin-top: 1.5rem;
    margin-bottom: 2.5em;
}

@media (min-width: 480px) and (max-width:767px) {
    .mob-img {
        display: block;
    }

    .top-sec {
        min-height: 0;
    }

    .top-sec::after {
        display: none;
    }

    .header-right {
        margin-right: 0;
    }

    .top-sec-details h1 {
        font-size: 39px;
    }

    .top-sec-details h4 {
        font-size: 19px;
    }

    .download-apps img {
        width: 48%;
    }

    .lap-img {
        display: none;
    }

    .sec {
        min-height: 35vh;
    }

    .left-side-img-1 {
        margin-left: 104px;
        margin-right: 0;
    }

    .left-side-img {
        margin-left: 104px;
        margin-right: 0;
    }

    .header-title {
        margin-top: 55px;
        font-size: 37px;
    }

    .detail-text {
        font-size: 21px;
    }

    .right-side-img {
        position: relative;

        bottom: 400px;
        margin-left: 110px;
    }

    .content-ml-auto {
        position: relative;
        top: 420px;
        margin-left: 0;
    }

    .btn-get-started {
        margin-bottom: 50px;
    }

    .partner_slider-content {
        padding: 400px 0px 0px 0px;
    }

    .partner_slider-content h1 {
        font-size: 31px;
        line-height: 35px;
    }

    .partner_slider-content p {
        font-size: 18px;
    }

    .partner-btn-groups {
        display: block;
        text-align: center;
    }

    .btn-partners {
        border-radius: 30px;
        padding: 16px 40px;
        font-weight: 600;
        width: 300px;
        font-size: 21px;
    }

    .play-video-btn {
        max-width: 300px;
        margin-left: 0;
        margin-top: 20px;
        border-radius: 30px;
        padding: 16px 40px;
        font-weight: 600;
        width: 300px;
        font-size: 20px;
    }

    .service-head h1 {
        font-size: 39px;
    }

    .section-image {
        background-size: contain;
        min-height: 36vh;
    }

    .section-image-content h2 {
        font-size: 37px;
    }

    .section-steps .title {
        text-align: center;
    }

    .title h4 {
        font-size: 33px;
    }

    .left-side-img-5 {
        margin-left: 97px;
        margin-bottom: 36px;
    }

    .signup-form {
        margin-bottom: 50px;
    }

    .header-text .form-section p {
        font-size: 17px;
    }


    .footer-content {
        display: block;
    }

    .footer-logo {
        margin-left: 150px;
    }

    .icons-li {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4px;
        margin-bottom: 15px;
        width: 50%;
    }

    .footer-menu {
        margin-left: 100px;
    }

    .form-section {
        max-width: 430px;
    }

    form button {
        border-radius: 15px;
    }
}



@media (min-width: 320px) and (max-width:479px) {
    .order {
        order: 2;
    }

    .mob-img {
        display: block;
    }

    .top-sec {
        min-height: 0;
    }

    .top-sec::after {
        display: none;
    }

    .header-right {
        margin-right: 0;
    }

    .top-sec-details h1 {
        font-size: 39px;
    }

    .top-sec-details h4 {
        font-size: 19px;
    }

    .download-apps img {
        width: 48%;
    }

    .lap-img {
        display: none;
    }

    .sec {
        min-height: 35vh;
    }

    .left-side-img-1 {
        /* margin-left: 60px; */
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .left-side-img {
        /* margin-left: 60px;
		margin-right: 0; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-title {
        margin-top: 55px;
        font-size: 37px;
    }

    .detail-text {
        font-size: 21px;
    }

    .right-side-img {
        /* position: relative;
    
    bottom: 530px;
    margin-left: 60px; */

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-ml-auto {
        /* position: relative;
    	top: 420px; */
        margin-left: 0;
    }

    .btn-get-started {
        margin-bottom: 50px;
    }

    .partner_slider-content {
        padding: 330px 0px 0px 0px;
    }

    .partner_slider-content h1 {
        font-size: 31px;
        line-height: 35px;
    }

    .partner_slider-content p {
        font-size: 18px;
    }

    .partner-btn-groups {
        display: block;
        text-align: center;
    }

    .btn-partners {
        border-radius: 30px;
        padding: 16px 40px;
        font-weight: 600;
        width: 300px;
        font-size: 21px;
    }

    .play-video-btn {
        max-width: 300px;
        margin-left: 0;
        margin-top: 20px;
        border-radius: 30px;
        padding: 16px 40px;
        font-weight: 600;
        width: 300px;
        font-size: 20px;
    }

    .service-head h1 {
        font-size: 39px;
    }

    .section-image {
        background-size: contain;
        min-height: 36vh;
    }

    .section-image-content h2 {
        font-size: 37px;
    }

    .section-steps .title {
        text-align: center;
    }

    .title h4 {
        font-size: 33px;
    }

    .left-side-img-5 {
        margin-left: 97px;
        margin-bottom: 36px;
    }

    .signup-form {
        margin-bottom: 50px;
    }

    .header-text .form-section p {
        font-size: 17px;
    }

    .footer {
        text-align: center !important;
    }


    .footer-content {
        display: block;
    }

    .footer-logo {
        /*margin-left: 150px;*/
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .icons-li {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4px;
        margin-bottom: 15px;
        width: 50%;
    }

    .footer-menu {
        /*margin-left: 100px;*/
    }

    .form-section {
        max-width: 430px;
    }

    form button {
        border-radius: 15px;
        padding: 6px 31px;
    }

    .contact-details {
        padding: 0px 0px 0px 25px;
    }


}

.par-txt {
    color: #fff !important;
    margin-bottom: 1rem !important;
}

.sub-header {
    text-align: center;
    margin-bottom: 1rem;
}

.section-content-signup {
    margin-bottom: 1rem;
}