@import url('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.2/css/bootstrap.min.css');
@import url('https://kit.fontawesome.com/f7ed9a3538.js');
  
  .card-title{ font-weight:700; }
  
  .btn{
    font-size: 14px;
    margin-top:20px;
  }
  
  .login-form{ 
    width:460px;
    margin:20px;
  }
  
  .sign-up{
    text-align:center;
    padding:20px 0 0;
  }
  
  span{
    font-size:14px;
  }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

#navbar{
    background-color: black;
    height: 70px;
    box-shadow: black 2px 2px 10px 0px;
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.nav-item a {
    text-decoration: none;
    color: rgb(228, 153, 47);
    font-weight: bold;
}

.center {
  margin-top: 4rem;
  margin-bottom: 4rem;
     text-align: center;
    height: 100%;

    /* Safari, Opera, and Chrome */
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;

    /* Firefox */
    display: -moz-box;
    -moz-box-pack: center;
    -moz-box-align: center;

    /* Internet Explorer 10 */
    display: -ms-flexbox;
    -ms-flex-pack: center;
    -ms-flex-align: center;
}


  .btn-primary {
    color: #000;
    background-color: #fba91f;
    border-color: #fba91f;
    font-size: 15px;
    font-weight: 600;
  }

  .card-1 {
    /* Add shadows to create the "card" effect */
    box-shadow:0 4px 8px 0 #fba919;
    transition: 0.3s;
    padding: 20px;
    background: #fba919;
    color: rgb(0 0 0 / 90%);
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #212529;
    outline: 0;
    box-shadow: 0 0 0 0.1rem #212529;
}

.form-control {
  border: 1px solid #000;
  text-align: left;
}

.btn-primary:hover {
    color: #fff;
    background-color: #fba91f;
    border-color: #fba91f;
}

span {
    font-size: 17px;
    font-weight: 500;
}

.a-tag{
    color: #000;
    text-decoration: underline;
}