.center {
  text-align: center;
}

.frame {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  background: black;
  border-radius: 28px 28px 0px 0px;
  z-index: 0;
  text-align: center;
  overflow: hidden;
}

.notch {
  width: 138px;
  height: 19.3px;
  background: #000;
  position: relative;
  z-index: 25;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: auto;
}

.iphone {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 544px;
  z-index: 10;
  top: 0;
  background: black;
}

.iphone img {
    width: 100%;
}


.iphone-mobile {
  position: relative;
  width: 256px;
  margin-left: 26vw;
  height: 544px;
  min-height: 544px;
  border-radius: 30px;
  box-shadow: 0 0 10px 11px #000;
  z-index: 1000;
  display: none;
}

.iphone-mobile img {
    width: 100%;
    /* border-radius: 30px; */
    display: none;
}

.frame-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 30px;
  box-shadow: 0px 0px 0px 11px #000, 0px 0px 0px 13px #000;
  z-index: 100;
  display: none;
}

.notch-mobile {
  width: 138px;
  height: 19.3px;
  background: #000;
  position: absolute;
  z-index: 100;
  border-bottom-left-radius: 15px;
  left: 59.1px;
  border-bottom-right-radius: 15px;
  display: none;
}

@media screen and (max-width: 980px) {

  .iphone {
    display: none;
  }

  .iphone-mobile {
    display: inline-block;
    margin: 25px auto 50px auto!important;
  } 

  .iphone-mobile img {
    display: inline-block;
    border-radius: 25px;
  }

  .frame-mobile {
    display: inline-block;
  }

  .notch-mobile {
    display: inline-block;
  }
}