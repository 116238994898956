.qr-code{
  max-width: 250px;
  max-height: 250px;
  transition-duration: 0.4s;
}



.mt-10{
  margin-top: 12px;
   text-align: center;
}

.store-wrapper{
  margin-top: 10px;
}

.gt-app-txt{
  font-size: 22px;
  margin-bottom: 0.1rem;
  line-height: 1;
}

.gt-app-txt-1{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 30px 0px 30px;
  margin-bottom: 0px;
  color: gray;
}


.gt-app-txt-2{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  color: gray;
}


.button-group {
  margin-top: 10px;
  width: 100%;
}

.d-d-b{
  margin-bottom: 0.8rem;
}


.disc-now-btn{
  width: 100%;
  height: 100%;
  border: 3px solid #F2A03F;
  font-weight: 600;
  border-radius: 4px;
  color: #000;
  padding: 12px 6px 10px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px 3px 3px 3px;
  cursor: pointer;
  transition-duration: 0.4s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.disc-now-btn:hover {
  background-color: #F2A03F;
  border: 3px solid #fff;
  color: #fff;
   text-decoration: none;
   transition-duration: 0.8s;
}


.download {
  display: inline-block;
  line-height: 48px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  letter-spacing: 2px;
  font-size: 12px;
  padding: 0px 0px;
}

.half-phone-box {
  height: 81%;
  width: 23%;
  max-width: 50%;
  overflow: hidden;
  background: transparent;
  position: fixed;
  bottom: 0px;
  right: 190px;
  text-align: center;
  z-index: 10000;
  border-radius: 30px 30px 0px 0px;
  box-shadow: 2px -5px 15px 2px black;
    box-shadow: 0px 0px 0px 11px #000, 0px 0px 0px 13px #000;

}

.center-i{
  text-align: center;
}

#section-1 {
  background: #fefbf5;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.store-button {
  margin-top: 4px;
  height: 38px;
  width: 130px;
}

.store-wrapper {
    display: flex;
}

body {
  margin: 0;
  font-family: 'Permanent Marker', cursive;
}

div#comparison { 
  width: 100%;
  height: 100%;
  /* overflow: hidden;  */
}

#divisor, #divisor1, #divisor2 {
  max-height: 100%;
}

div#comparison figure { 
  background-image: url(https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/image1-min.png); 
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  width: 100%; 
  height: 100%;
  margin: 0; 
  z-index: 1;
}

div#comparison figure > img { 
  position: relative;
  width: 100%;
  border-radius: 0px;
    z-index: 1;
    height: 100%;

}

div#comparison figure div:nth-child(1) { 
  background-image: url(https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/image3-min.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%; 
  box-shadow: 0 5px 10px -2px rgba(0,0,0,0.3);
  overflow: hidden;
  bottom: 0;
  height: 0%;
  z-index: 1;
}

div#comparison figure div:nth-child(2) { 
  background-image: url(https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/image2-min.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%; 
  box-shadow: 0 5px 10px -2px rgba(0,0,0,0.3);
  overflow: hidden;
  bottom: 0;
  height: 0%;
  z-index: 1;
  max-height: 100%;
}

div#comparison figure div:nth-child(3) { 
  background-image: url(https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/image4-min.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%; 
  box-shadow: 0 5px 10px -2px rgba(0,0,0,0.3);
  overflow: hidden;
  bottom: 0;
  height: 0%;
  z-index: 1;
}

.jwpVBP {
    transition: all 0.3s linear 0s;
    transform-origin: center center;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: rgb(0, 0, 0);
    margin-bottom: 25px;
}

.gOrZUu {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    overflow: visible;
}

.active {
  transform: scale(1.5);
}

.white {
  background: white;
  transition: all .5s ease-in-out; 
}

.left {
  padding-top: 8%;
}

.mb-10{
  margin-bottom: 10px;
}

@media screen and (max-width: 600px){
.app-link-css {
  flex: auto;
  display: flex;
  justify-content: left;
}

.d-l-b-a-i {
  display: flex;
  width: 280px;
  max-width: 280px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
}

@media screen and (max-width: 980px) {
  .qr-code{
    max-width: 300px;
    max-height: 300px;
    transition-duration: 0.4s;
  }
  
  .left {
    padding-top: 2%;
  }

  .left h1{
    font-size: 32px;
  }

  .button-group {
    margin-top: 28px;
  }
  
  .gOrZUu {
    display: none;
  }

  .half-phone-box {
    display: none;
  }

  #section-1 {
    padding: 25px;
  }

  .store-wrapper {
    margin-bottom: 20px!important;
  }

  .left{
    justify-content: center;
    align-items: center;
    text-align: center;
  }

   .app-link-css{
    flex: auto;
    display: flex;
    justify-content: left;
  }


  .disc-now-btn{
    width: 70%;
    height: 100%;
    border: 3px solid #F2A03F;
    font-weight: 600;
    border-radius: 4px;
    color: #000;
    padding: 12px 6px 10px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 3px 3px 3px 3px;
    cursor: pointer;
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .disc-now-btn:hover {
    background-color: #F2A03F;
    border: 3px solid #fff;
    color: #fff;
     text-decoration: none;
     transition-duration: 0.8s;
  }

  .d-l-b-a-i {
    display: flex;
    width: 300px;
    max-width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (min-width: 981px) and (max-width:1199px) { 
  .qr-code{
    max-width: 300px;
    max-height: 300px;
    transition-duration: 0.4s;
  }
  .half-phone-box {
    height: 81%;
    width: 28%;
    max-width: 50%;
    overflow: hidden;
    background: transparent;
    position: fixed;
    bottom: 0px;
    right: 120px;
    text-align: center;
    z-index: 10000;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 2px -5px 15px 2px black;
    box-shadow: 0px 0px 0px 11px #000, 0px 0px 0px 13px #000;
  }


  .disc-now-btn{
    width: 50%;
    height: 100%;
    border: 3px solid #F2A03F;
    font-weight: 600;
    border-radius: 4px;
    color: #000;
    padding: 12px 6px 10px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 3px 3px 3px 3px;
    cursor: pointer;
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .disc-now-btn:hover {
    background-color: #F2A03F;
    border: 3px solid #fff;
    color: #fff;
     text-decoration: none;
     transition-duration: 0.8s;
  }

  .d-l-b-a-i {
    display: flex;
    width: 320px;
    max-width: 320px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
 }

@media screen and (min-width: 1200px) { 

  .qr-code{
    max-width: 350px;
    max-height: 350px;
    transition-duration: 0.4s;
  }

  .half-phone-box {
    height: 81%;
    width: 22%;
    max-width: 50%;
    overflow: hidden;
    background: transparent;
    position: fixed;
    bottom: 0px;
    right: 176px;
    text-align: center;
    z-index: 10000;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 2px -5px 15px 2px black;
    box-shadow: 0px 0px 0px 11px #000, 0px 0px 0px 13px #000;
  }
  
  .left{
    padding-top: 8%;
  }

  h1 {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
  }

  .disc-now-btn{
    width: 50%;
    height: 100%;
    border: 3px solid #F2A03F;
    font-weight: 600;
    border-radius: 4px;
    color: #000;
    padding: 12px 6px 10px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 3px 3px 3px 3px;
    cursor: pointer;
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .disc-now-btn:hover {
    background-color: #F2A03F;
    border: 3px solid #fff;
    color: #fff;
     text-decoration: none;
     transition-duration: 0.8s;
  }


  .d-l-b-a-i {
    display: flex;
    width: 390px;
    max-width: 390px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
