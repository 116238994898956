#section-2 h1 {
    color: white;
}

#section-2 h4 {
    color: white;
}

#section-2 {
    height: 100vh;
    width: 100vw;
    /* background: linear-gradient(135deg, #FF5A63 0%, #872DEB 50%, #872DEB 100%); */
    background: linear-gradient(135deg, #797979 0%, #1E1E1E 50%, #1E1E1E 100%);
    position: relative;
}

@media screen and (max-width: 980px) {

    #section-2{
        padding: 25px;
        height: auto!important;
    }

    #section-2 .left {
        padding-top: 50px;
    }
}